import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  Autocomplete
} from "@mui/material";
import React, { useState } from "react";
import PersonPhoto from "./PersonPhoto";
import { useImmerUpdaters } from "../../utils/useImmerUpdaters";
import { styles } from "../../styles/actionCardsStyles";
import stateList from "../../data/stateList.json";
import idDocumentsTypes from "../../data/idDocumentsTypes.json";
import languagePref from "../../data/languagePref.json";
import { getAge, deltaDate } from "../../utils/getAge";
import { Profile } from "../../dataTypes/person/personTypes";
import { PersonViewResponse } from "../../dataTypes/person/personRequestTypes";
import { Audited } from "../../dataTypes/shared/sharedRequestTypes";
import DropdownMenu from "../common/DropdownMenu";
import InputField from "../common/InputField";
import countryList from "../../data/countryList.json";

const isNotEmptyString = (str?: string | null) =>
  str != null && str.trim().length > 0;

interface personInfoProps {
  personInfo: Audited<Profile>;
  personDetails: PersonViewResponse | null;
  isProtectedPerson: boolean;
  onUpdate: (profile: Audited<Profile>) => void;
}

const PersonProfile = ({
  personInfo,
  personDetails,
  isProtectedPerson,
  onUpdate,
}: personInfoProps) => {
  const [personInformation, setPersonInformation] = useState(personInfo);
  const [updatePersonInfo, handleUpdatePersonInfo] = useImmerUpdaters(
    personInformation,
    setPersonInformation,
  );
  const [showErrors, setShowErrors] = useState(false);
  const idType = personInformation.idDocuments[0]?.type;
  const isUSA = personInfo.country === "USA";
  const isCAN = personInfo.country === "CAN";
  const isDeceasedPerson = personInfo.status === "Deceased";

  // Form Validity
  const age = getAge(personInformation.birthDate);
  const minDate = deltaDate(new Date(), 0, 0, -120).toISOString().split("T")[0];
  const maxDate = deltaDate(new Date(), 0, 0, -16).toISOString().split("T")[0];
  const isIdStateRequired = idType !== "PASSPORT" && idType !== "MILITARYID";
  const isIdSCountryRequired = idType !== "PASSPORT";

  const isFieldValid = {
    age: age >= 16 && age <= 120,
    firstName: isNotEmptyString(personInformation.firstName),
    lastName: isNotEmptyString(personInformation.lastName),
    idDocType: (isUSA && (isNotEmptyString(personInformation.idDocuments[0]?.type)) || isCAN),
    idDocNumber: (isUSA && (isNotEmptyString(personInformation.idDocuments[0]?.number))|| isCAN),
    idDocState: (isUSA && (isNotEmptyString(personInformation.idDocuments[0]?.stateProvince))|| isCAN),
    idDocCountry: (isUSA && (isNotEmptyString(personInformation.idDocuments[0]?.country))|| isCAN),
  };
  const isFormValid = Object.keys(isFieldValid).every((k) => isFieldValid[k]);

  // Check Valid Id Documents change
  const handleIDDocNumberChange = (event: { target: { value: string } }) => {
    updatePersonInfo((p) => {
      p.idDocuments[0].number = event.target.value;
    });
  };

  // Check Id Documents Type
  const handleIDTypeChange = (event: { target: { value: string } }) => {
    const newIdType = event.target.value;
    if (newIdType === "PASSPORT") {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
        p.idDocuments[0].stateProvince = "";
      });
    } else if (newIdType === "MILITARYID") {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
        p.idDocuments[0].stateProvince = "";
      });
    } else if (newIdType === "NONE") {
      updatePersonInfo((p) => {
        p.idDocuments = [];
      });
    } else {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
      });
    }
  };

  // Handle Error
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!isFormValid) {
      setShowErrors(true);
      return;
    }
    onUpdate(personInformation);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile
      </Typography>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {/* Person photo */}
        <Grid item xs={12} sm={12}>
          <PersonPhoto personDetails={personDetails} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            name="firstName"
            label="First Name"
            value={personInformation?.firstName || ""}
            onChange={handleUpdatePersonInfo((p, v) => (p.firstName = v))}
            error={showErrors && !isFieldValid.firstName}
            disabled={isProtectedPerson}
            helperText={
              showErrors && !isFieldValid.firstName ? "First Name is required" : " "
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputField
            name="middleInitial"
            label="Middle Initial"
            value={personInformation?.middleInitial || ""}
            onChange={handleUpdatePersonInfo((p, v) => (p.middleInitial = v))}
            disabled={isProtectedPerson}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            name="lastName"
            label="Last Name"
            value={personInformation?.lastName || ""}
            onChange={handleUpdatePersonInfo((p, v) => (p.lastName = v))}
            error={showErrors && !isFieldValid.lastName}
            disabled={isProtectedPerson}
            helperText={
              showErrors && !isFieldValid.lastName ? "Last Name is required" : " "
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputField
            name="suffix"
            label="Suffix"
            value={personInformation?.suffix || ""}
            onChange={handleUpdatePersonInfo((p, v) => (p.suffix = v))}
            disabled={isProtectedPerson}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12}>
          <InputField
            name="birthDate"
            label="Birth Date"
            type="date"
            value={personInformation?.birthDate || ""}
            onChange={handleUpdatePersonInfo((p, v) => (p.birthDate = v))}
            error={showErrors && !isFieldValid.age}
            disabled={isProtectedPerson}
            helperText="Must be 16 to 120 years old"
            inputProps={{ min: minDate, max: maxDate }}
          />
        </Grid>
      </Grid>
      {personInformation?.idDocuments?.map(() => (
        <Box component="section" sx={styles.boxContainer}>
          <Typography variant="subtitle1" sx={styles.boxSubTitle}>
            <b>ID Documents</b>
          </Typography>
          <Grid container columnSpacing={3}>
            <DropdownMenu
              field={{
                label: "Type",
                value: personInformation.idDocuments[0]?.type || "",
                id: "idDocumentType",
                name: "idDocumentType",
                onChange: handleIDTypeChange,
              }}
              options={[
                  ...Object.entries(idDocumentsTypes),
                  ...([{ key: "NONE", value: "None", disabled: isUSA }])
                ]}
            />
            <Grid item xs={12} sm={6}>
              <InputField
                name="idDocumentNumber"
                label="Number"
                value={personInformation.idDocuments[0]?.number || ""}
                onChange={handleIDDocNumberChange}
                error={showErrors && !isFieldValid.idDocNumber}
                disabled={isProtectedPerson}
                helperText={
                  showErrors && !isFieldValid.idDocNumber ? "Invalid ID Number" : " "
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="idDocumentStateProvince"
                options={
                  personInformation.idDocuments[0]?.country === "USA"
                    ? stateList.USA
                    : stateList.CAN
                }
                value={personInformation.idDocuments[0]?.stateProvince || ""}
                onChange={(e, v) =>
                  updatePersonInfo((p) => (p.idDocuments[0].stateProvince = v))
                }
                renderInput={(params) => (
                  <InputField
                    {...params}
                    name="idDocumentStateProvince"
                    label="State"
                    value={personInformation.idDocuments[0]?.stateProvince || ""}
                    error={showErrors && !isFieldValid.idDocState}
                    helperText={
                      showErrors && !isFieldValid.idDocState
                        ? "Invalid State Province"
                        : " "
                    }
                    disabled={personInformation.idDocuments[0]?.type === "MILITARYID" || personInformation.idDocuments[0]?.type === "PASSPORT"}
                    required={!(personInformation.idDocuments[0]?.type === "MILITARYID" || personInformation.idDocuments[0]?.type === "PASSPORT")}
                  />
                )}
                disabled={(isCAN || !isIdStateRequired) && isProtectedPerson}
              />
            </Grid>
            <DropdownMenu
              field={{
                label: "Country",
                value: personInformation.idDocuments[0]?.country || "",
                id: "idDocumentCountry",
                name: "idDocumentCountry",
                onChange: handleUpdatePersonInfo(
                  (p, v) => (p.idDocuments[0].country = v)
                ),
                disabled: isCAN || !isIdSCountryRequired,
                required: isUSA && isIdSCountryRequired,
              }}
              options={Object.entries(countryList)}
            />
          </Grid>
        </Box>
      ))}
      <Grid container spacing={3}>
        <DropdownMenu
          field={{
            label: "Country",
            value: personInformation?.country || "",
            id: "profile-country",
            name: "country",
            onChange: handleUpdatePersonInfo((p, v) => (p.country = v)),
          }}
          options={Object.entries(countryList)}
        />
        <DropdownMenu
          field={{
            label: "Language Preference",
            value: personInformation.languagePref || "",
            id: "languagePref",
            name: "languagePref",
            onChange: handleUpdatePersonInfo((p, v) => (p.languagePref = v)),
          }}
          options={
            languagePref[personInformation.country]?.map((lang) => [lang, lang]) || []
          }
        />
      </Grid>
      <Grid container spacing={3} sx={{ mb: 3 }}>
      <DropdownMenu
        field={{
          label: "Status",
          value: personInformation?.status || "",
          id: "statusDropdown",
          name: "status",
          onChange: handleUpdatePersonInfo((p, v) => (p.status = v)),
        }}
        options={[
          { key: "Non-Violator", value: "Non-Violator" }, // Enabled
          { key: "Violator", value: "Violator", disabled: true }, // Disabled
          { key: "Forgotten", value: "Forgotten", disabled: true }, // Disabled
          { key: "Deceased", value: "Deceased", disabled: true }, // Disabled
        ]}
      />
        <Grid item xs={12} sm={6}>
          <InputField
            name="statusDate"
            label="Status Date"
            type="date"
            value={
              personDetails?.person?.profile?.statusDate
                ? personDetails.person.profile.statusDate.split("T")[0]
                : ""
            }
            onChange={handleUpdatePersonInfo((p, v) => (p.statusDate = v))}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        // disabled={isProtectedPerson || isDeceasedPerson}
      >
        Update Profile
      </Button>
    </Paper>
  );
};

export default PersonProfile;
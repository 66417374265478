import React, { FC, useEffect, useState, useMemo } from "react";
import {
  Button,
  Container,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import { AccountComponentProps } from "../../dataTypes/account/accountComponentsTypes";
import { Action } from "../../dataTypes/shared/sharedRequestTypes";
import InputField from "../common/InputField";
import DropdownMenu from "../common/DropdownMenu";
import countryList from "../../data/countryList.json";
import accountStatuses from "../../data/accountStatuses.json";
import { Account } from "../../dataTypes/account/accountTypes";

// @TODO: paths should be read from config files
const AllActions: Action[] = [
  {
    code: "Modify",
    text: "Modify",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/modify-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams:
        "mgloTransactionType,expirationDate,tierStartDate,beginDate,statusDate,awarenessCode,awarenessDescription",
    },
  },
  {
    code: "Activate",
    text: "Activate",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/activate-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Renew",
    text: "Renew",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/renew-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Cancel",
    text: "Cancel",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/cancel-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Expire",
    text: "Expire",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/expire-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Close",
    text: "Close",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/close-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
];

const AccountComponent: FC<AccountComponentProps> = ({
  accountData,
  accountId,
  onUpdate,
}) => {
  const [account, setAccount] = useState({ ...accountData, accountId });
  const [updatePayload, setUpdatePayload] = useState({
    accountId: accountId,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const accountStatusOptions = useMemo(
    () => Object.entries(accountStatuses),
    []
  );

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setUpdatePayload({
      ...updatePayload,
      [name]: name === "autoRenew" ? checked : value,
    });
    setAccount({ ...account, [name]: name === "autoRenew" ? checked : value });
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: Action
  ) => {
    e.preventDefault();
    let payload: {
      accountId: string;
      mgloTransactionType?: string;
      tier?: string;
    } = {
      accountId: account.accountId,
    };
    if (action.code === "Modify") {
      payload = { ...updatePayload, mgloTransactionType: "MGLOUpdate" };
    } else if (action.code === "Upgrade") {
      payload.tier = "Executive";
    } else if (action.code === "Downgrade") {
      payload.tier = "Regular";
    } else if (action.code === "Renew") {
      payload.tier = account.tier;
    }
    setIsSubmitting(true);
    onUpdate({
      payload: {
        path: action.api.url,
        mgloTransactionType: `MGLO${action.code}`,
        ...payload,
      },
      code: action.code,
    });
    setIsSubmitting(false);
  };

  function isBusinessAccount (account: Account) {
    return account.type == "Business" ? true : false;
  }

  const handleOverwriteSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

     // @TODO: paths should be read from config files
    const path = (isBusinessAccount(account) ? "/mglo-manage-account/v1/overwrite-business-account" : "/mglo-manage-account/v1/overwrite-consumer-account")
    console.log(path);

    const payload = {
      accountId: account.accountId,
      mgloTransactionType: "CRMSignup",
      createdByChannel: "Process",
      CreatedAtLocationNumber: 99, 
      awarenessCode: account.awarenessCode,
      beginDate: account.beginDate ? account.beginDate.split("T")[0] : "",
      country: account.country,
      type: account.type,
      tierStartDate: account.tierStartDate ? account.tierStartDate.split("T")[0] : "",
      statusDate: account.statusDate ? account.statusDate.split("T")[0] : "",
      tier: account.tier,
      legacyAccountId: account.legacyAccountId,
      homeLocationNumber: account.homeLocationNumber,
      locationNumber: account.locationNumber,
      status: account.status,
      organizationId: account.organizationId
    };
  
    setIsSubmitting(true);
    onUpdate({
      payload: {
        path: path,
        ...payload,
      },
      code: "Overwrite",
    });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!account.actions || account.actions?.length === 0) {
      setAccount({ ...account, actions: AllActions });
    }
  }, [account]);

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Account Information
        </Typography>
        <InputField
          name="AccountId"
          label="Account Id"
          value={account.accountId}
          disabled
        />

        <InputField name="type" label="Type" value={account.type} disabled />
        <InputField
          name="legacyAccountId"
          label="Legacy Account Id"
          value={account.legacyAccountId}
          disabled
        />

        {account.country && (
          <DropdownMenu
            field={{
              label: "Account Country",
              value: account.country,
              id: "countryDropdown",
              name: "country",
              onChange: handleChange,
            }}
            disabled
            options={Object.entries(countryList)}
          />
        )}

        <Grid container spacing={2}>
          <DropdownMenu
            field={{
              label: "Account Status",
              value: account.status,
              id: "statusDropdown",
              name: "status",
              onChange: handleChange,
            }}
            options={accountStatusOptions}
          />

          <Grid item xs={12} md={6}>
            <InputField
              name="statusDate"
              label="Status Date"
              value={account.statusDate ? account.statusDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              name="awarenessCode"
              label="Awareness Code"
              value={account.awarenessCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="awarenessDescription"
              label="Awareness Description"
              value={account.awarenessDescription}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              name="locationNumber"
              label="Location Number"
              value={account.locationNumber}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="homeLocationNumber"
              label="Home Location Number"
              value={account.homeLocationNumber}
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              name="beginDate"
              label="Begin Date"
              value={account.beginDate ? account.beginDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="expirationDate"
              label="Expiration Date"
              value={account.expirationDate ? account.expirationDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              name="tier"
              label="Tier"
              value={account.tier}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="tierStartDate"
              label="Tier Start Date"
              value={account.tierStartDate ? account.tierStartDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
            />
          </Grid>
        </Grid>

        <InputField
          name="organizationId"
          label="organization Id"
          value={account.organizationId}
          onClick={() =>
            navigate(
              `../../ManageOrganization/retrieveOrganization?orgID=${account.organizationId}`
            )
          }
          InputProps={{
            readOnly: true,
          }}
        />

        <FormControlLabel
          name="autoRenew"
          labelPlacement="start"
          control={
            <Switch
              checked={account.autoRenew}
              onChange={handleChange}
              inputProps={{ "aria-label": "Auto Renew" }}
            />
          }
          label="Auto Renew"
        />
      </Paper>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Account Action
        </Typography>
        <Grid container spacing={2}>
          {account.actions?.map((val) => (
            <Grid item key={val.code}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e, val)}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : val.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Overwrite Protected Account Fields
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              name="locationNumber"
              label="Location Number"
              value={account.locationNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="homeLocationNumber"
              label="Home Location Number"
              value={account.homeLocationNumber}
              onChange={handleChange}
            />
          </Grid>
            <DropdownMenu
              field={{
                label: "Country",
                value: account.country,
                id: "countryDropdown",
                name: "country",
                onChange: handleChange,
              }}
              options={Object.entries(countryList)}
            />
          <Grid item xs={12} md={6}>
            <InputField
              name="statusDate"
              label="Status Date"
              value={account.statusDate ? account.statusDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOverwriteSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Submit Overwrite"}
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AccountComponent;

import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Button,
  Grid,
  Typography,
  Paper
} from "@mui/material";
import memberRoles from "../../data/memberRoles.json";
import countryList from "../../data/countryList.json";
import memberStatuses from "../../data/memberStatuses.json";
import { styles } from "../../styles/generalStyles";
import MemberEligibilityDialog from "./MemberEligibilityDialog";
import InputField from "../common/InputField";
import DropdownMenu from "../common/DropdownMenu";

const ACTIONS = {
  ACCEPT_PNC: "AcceptPnC",
  ESTABLISH: "Establish",
  REINSTATE: "Reinstate",
  TERMINATE: "Terminate",
};

const defaultActionTexts = [
  ACTIONS.ACCEPT_PNC,
  ACTIONS.ESTABLISH,
  ACTIONS.REINSTATE,
  ACTIONS.TERMINATE,
];

function mapActionTextToUrl(text) {
  switch (text) {
    case ACTIONS.ACCEPT_PNC:
      return "accept-privileges-and-conditions";
    case ACTIONS.ESTABLISH:
      return "establish-member";
    case ACTIONS.REINSTATE:
      return "reinstate-member";
    case ACTIONS.TERMINATE:
      return "terminate-member";
    default:
      throw new Error(`Unexpected action text: ${text}`);
  }
}

function getActions(memberView, actionTexts) {
  if (memberView.status === "Pending") {
    if (memberView.pncAcknowledgementDate === undefined) {
      return actionTexts.slice(1).map(() => ({
        text: ACTIONS.ACCEPT_PNC,
        path: mapActionTextToUrl(ACTIONS.ACCEPT_PNC),
      }));
    } else {
      return actionTexts.slice(1).map(() => ({
        text: ACTIONS.ESTABLISH,
        path: mapActionTextToUrl(ACTIONS.ESTABLISH),
      }));
    }
  } else {
    return actionTexts.map((text) => ({
      text,
      path: mapActionTextToUrl(text),
    }));
  }
}

function ActionButtons({ actions, isSubmitting, handleActionClick }) {
  return actions.map((action) => (
    <Grid item key={action.path}>
      <Button
        variant="contained"
        onClick={() =>
          handleActionClick(action.path, `Action '${action.text}' successful!`)
        }
        disabled={isSubmitting}
      >
        {action.text}
      </Button>
    </Grid>
  ));
}

const MemberComponent = ({ memberInfoData, memberDetailsData, onUpdate, onAction }) => {
  const [member, setMember] = useState(memberInfoData);
  const [memberView, setMemberView] = useState(memberDetailsData.member);
  const [enrollmentObj, setEnrollment] = useState(memberInfoData.enrollment);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const cardNumber = memberDetailsData?.cards[0]?.number;

  // Memoize the dropdown options so they're not re-created on every render
  const memberRoleOptions = useMemo(() => Object.entries(memberRoles), []);
  const countryOptions = useMemo(() => Object.entries(countryList), []);
  const memberStatusOptions = useMemo(() => Object.entries(memberStatuses), []);

  const handleChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setMember((prev) => ({ ...prev, enrollment: enrollmentObj }));
  }, [enrollmentObj]);

  const handleEnrollment = (e) => {
    setEnrollment((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onUpdate(member);
    setIsSubmitting(false);
  };

  const handleVerifyEligibilityClick = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  const handleDialogClose = (e) => {
    e.preventDefault();
    setOpenDialog(false);
  };

  const handleActionClick = async (actionURL, onSuccessMessage) => {
    setIsSubmitting(true);
    await onAction(actionURL, member, onSuccessMessage);
    setIsSubmitting(false);
  };

  const actionTexts =
    memberView.actions.length > 0
      ? memberView.actions.map((action) => action.text)
      : defaultActionTexts;

  const actions = getActions(memberView, actionTexts);

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Member Information
        </Typography>

        <InputField
          name="legacyMemberId"
          label="Legacy Member Id"
          value={member.legacyMemberId}
          onChange={handleChange}
          disabled={true}
        />

        <InputField
          name="accountId"
          label="Account Id"
          value={member.accountId}
          onChange={handleChange}
        />

        <Grid container spacing={2}>
            <DropdownMenu
              field={{
                label:"Member Status",
                value: member.status,
                id: "statusDropdown",
                name: "status",
                onChange: handleChange,
              }}
              options={memberStatusOptions}
          />
          <Grid item xs={12} md={6}>
            <InputField
              name="statusDate"
              label="Status Date"
              value={member.statusDate ? member.statusDate.split("T")[0] : ""}
              onChange={handleChange}
              type="date"
            />
          </Grid>
        </Grid>

        <DropdownMenu
          field={{
            label: "Member Role",
            value: member.role,
            id: "roleDropdown",
            name: "role",
            onChange: handleChange,
          }}
          options={memberRoleOptions}
        />

        <DropdownMenu
          field={{
            label: "Enrollment Country",
            value: enrollmentObj.country,
            id: "countryDropdown",
            name: "country",
            onChange: handleEnrollment,
          }}
          options={countryOptions}
        />

        <InputField
          name="locationNumber"
          label="Enrollment Location Number"
          value={enrollmentObj.locationNumber}
          onChange={handleEnrollment}
        />

        <InputField
          name="date"
          label="Enrollment Date"
          value={enrollmentObj.date ? enrollmentObj.date.split("T")[0] : ""}
          onChange={handleEnrollment}
          type="date"
        />

        <InputField
          name="pncAcknowledgementDate"
          label="PnC Acknowledgement Date"
          value={enrollmentObj.pncAcknowledgementDate || ""}
          onChange={handleEnrollment}
          type="date"
        />

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Overwrite Member Info
            </Button>
          </Grid>

          {/* Reusable ActionButtons component */}
          <ActionButtons
            actions={actions}
            isSubmitting={isSubmitting}
            handleActionClick={handleActionClick}
          />

          <Grid item>
            <Button
              variant="contained"
              key="verify-eligibility"
              onClick={handleVerifyEligibilityClick}
              disabled={isSubmitting || memberDetailsData.cards.length === 0}
            >
              Verify Member Eligibility
            </Button>
          </Grid>
        </Grid>

        <MemberEligibilityDialog
          memberDetailsData={memberDetailsData}
          open={openDialog}
          handleDialogClose={handleDialogClose}
        />
      </Paper>
    </Container>
  );
};

export default MemberComponent;